import {Outlet} from "react-router-dom"
import * as React from "react"
import {Header, } from "./index"
import {useApp} from "../contexts/appContext"

type AppLayoutType = {
    children: React.ReactNode,
    goBack?: string
}

export default function AppLayout({goBack, children}: AppLayoutType) {
    
    const {labels} = useApp()

    return (
        <React.Fragment>
            <Header goBack={goBack} />
            <div className='vh-100'>
                <div className='py-5 px-4'>
                    {children}
                    <Outlet />
                </div>
            </div>
        </React.Fragment>
    );
}
