import * as React from "react"
import {AppLayout} from "../layouts"
import {Button, Equipment, EquipmentList, Loader, Search} from "../components";
import {useNavigate} from "react-router-dom";
import { EquipmentType } from "../type-def";

export default function AppScreen() {

    const [data, setData] = React.useState<EquipmentType[]>([]);
    const [loading, isLoading] = React.useState<boolean>(false);

    const navigate = useNavigate();

    const onResult = (list: EquipmentType[]) => {
        setData(list)
    }

    React.useEffect(() => {

    }, [])

  
    return (
        <AppLayout>
            <React.Fragment>
                <Search handleResult={onResult} />
                {data.length > 0 && <EquipmentList data={data} />}
            </React.Fragment>
        </AppLayout>
    );
}
