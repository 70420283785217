import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { EquipmentType, SearchType } from '../type-def';
import { myList } from '../var/faker';
import queryString from 'query-string';

export default function Search({handleResult}: SearchType) {

    const [search, setSearch] = useState<string | undefined>(undefined)
    const [data, setData] = useState<EquipmentType[]>(myList)

    const parsed = queryString.parse(window.location.search);
    
    useEffect(() => {
        if(window.location.search && parsed !== undefined) {
            console.log('__',parsed);
            setData(myList);
            handleResult(myList);
        }

    }, [])

    return (
        <>
            <p className='my-2 text-muted text-center small'>
                Renseignez l'adresse d'un site pour pouvoir appliquer une mise en conformité
            </p>
            
            <div className="input-group mb-3">
                <input 
                    type="text" 
                    className="form-control rounded-0" 
                    placeholder="Saississez l'adresse ou le N° d'idenfication du site"
                    defaultValue={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                    <button 
                        className="btn btn-outline-secondary rounded-0" 
                        type="button"
                        onClick={() => handleResult(data)}
                    >
                        {/*@ts-ignore*/}
                        <FontAwesomeIcon className='fa-2x' icon={faSearch} />
                    </button>
                </div>
            </div>
        </>
    )
}



