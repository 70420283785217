import React from "react";
import { EquipmentType } from "../type-def";
import { Link } from "react-router-dom";

export default function EquipmentList({data}: any) {
    return <div>
        <p>Equipements trouvés: <b>{data.length}</b></p>
        {data.length > 0 && data.map((item: EquipmentType) => (
            <div key={item.id}>
                <Link to={`/ept/${item.slug}`}>
                    <Row {...item} />
                </Link>
            </div>
        ))}
        
    </div>
}

function Row({designation, entity_name, control_count, location}: EquipmentType) {
    return <div className="d-flex justify-content-between border-bottom my-1 bg-light p-2">
        <div className="w-100">
            <div className="text-uppercase">
                <b>{designation}</b>
            </div>
            <div className="small mb-1">{entity_name}</div>
        </div>
        <div className="w-100 d-flex justify-content-end">
            <div>
                <div>{control_count} contrôle(s)</div>
                <div className="small mb-1">{location}</div>
            </div>
        </div>
    </div>
}
