import * as React from "react"
import {Language} from "../components"
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, } from '@fortawesome/free-solid-svg-icons'
export default function Header({goBack}: {goBack?: string}) {

    return (
        <header className='mb-4'>
            <nav className="navbar bg-dark navbar-expand-lg fixed-top mask-custom shadow border-bottom">
                <div className='d-flex justify-content-between align-items-center w-100 px-4 py-2'>
                    {goBack && (
                        <Link to={goBack}>
                            {/*@ts-ignore*/}
                            <FontAwesomeIcon className='fa-2x text-white px-2' icon={faArrowCircleLeft} />
                        </Link>
                    )}
                    
                    <div className="w-100">
                        <Link to='/'>
                            <img style={{width: 40}} src="/logo.png" alt="Logo ARCI" />
                        </Link>
                    </div>

                    <div className='d-flex justify-content-end'>
                        <Language />
                    </div>
                </div>
            </nav>
        </header>
    )
}
