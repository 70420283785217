import React from "react";
import { EquipmentType } from "../type-def";

export default function Equipment({code, designation, provider, manufacture_date, expiration_date, equipment_type_name, control_count, intervention_count}: EquipmentType) {
    return <div>
        <Row label="Désignation" value={designation}/>
        <Row label="Code" value={code}/>
        <Row label="Type d'équipement" value={equipment_type_name}/>
        <Row label="Fournisseur" value={provider}/>
        <Row label="Date fabrication" value={manufacture_date}/>
        <Row label="Date expiration" value={expiration_date}/>
        <Row label="Interventions" value={intervention_count}/>
        <Row label="Contrôles" value={control_count}/>
        
    </div>
}

function Row({label, value}: {label: string, value?: any}) {
    return <div className="d-flex justify-content-between border-bottom my-2">
        <div className="w-100 text-uppercase small">{label}</div>
        <div className="w-100 d-flex justify-content-end small">{value}</div>
    </div>
}
