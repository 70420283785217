import * as React from "react"
import {AppLayout} from "../layouts"
import {Button, ControlForm, Equipment, Loader} from "../components";
import { EquipmentType } from "../type-def";
import { useParams } from 'react-router-dom';
import { myList } from "../var/faker";

export default function AppScreen() {

    const [item, setItem] = React.useState<EquipmentType | undefined>(undefined);
    const [loading, isLoading] = React.useState<boolean>(false);

    const { slug } = useParams();

    const options = [
        { id: 'Conforme', label: 'Conforme', theme: 'success' },
        { id: 'Non-conforme', label: 'Non conforme', theme: 'danger' },
        { id: 'Reserve', label: 'Réserve', theme: 'warning' }
      ];

    React.useEffect(() => {
        setItem(myList[0])
    }, [])

  
    return (
        <AppLayout goBack={`/home?slug=${slug}`}>
            <React.Fragment>
                {item !== undefined && <>
                    <h6 className="text-uppercase mt-4">{item.designation}</h6>
                    <p>{item.entity_name}</p>
                    <Equipment {...item} />
                    <hr />
                    <ControlForm options={options} />
                </>}
            </React.Fragment>
        </AppLayout>
    );
}
