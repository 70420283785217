import { EquipmentType } from "../type-def";

export const myList : EquipmentType[] = [
    {
        designation: "Ria CO2 Noir",
        code: "RIA-00155/032024",
        id:10,
        slug: "x90dc9a7e-ff78",
        provider: 'Universal Fire',
        manufacture_date: "12/05/2021",
        expiration_date: "21/11/2025",
        equipment_type_name: "Ria",
        entity_name: "Beevance group",
        location: "Salon",
        control_count: 2,
        intervention_count: 1
    },

    {
        designation: "Ria en poudre blanc",
        code: "Foo-0015",
        id:12,
        slug: "01d5dkshjs0dd0dc9a7e-hb145dplkkf4",
        provider: 'Beevance',
        manufacture_date: "10/08/2019",
        expiration_date: "11/01/2025",
        equipment_type_name: "Extincteur en poudre",
        entity_name: "Beevance group",
        location: "Couloir",
        control_count: 0,
        intervention_count: 2
    },
]