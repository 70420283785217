import React, { useState } from 'react';

export default function ControlForm({options}: any) {
  
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <div>
        <h6>Rapport de conformité</h6>
        {options.map((option: any)=> (
          <div key={option.id} className={`d-flex aleig-item-center my-2 rounded shadow p-2 bg-${option.theme}`}>
                <label htmlFor={option.id} className={`form-check-label d-block my-2`}>
                    <input
                        type="radio"
                        name="options"
                        className="form-check-input mx-2"
                        value={option.id}
                        onChange={handleOptionChange}
                        checked={selectedOption === option.id}
                        id={option.id}
                    />
                    <span className={`badge bg-white text-uppercase text-black`}>
                        {option.label}
                    </span>
                </label>
          </div>
        ))}
      </div>
      <p>Rapport: {selectedOption}</p>
    </>
  );
}
